import React, { Component } from "react";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import moment from "moment-timezone";
import { injectIntl, FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from "../Tooltip.js";

import { withAuth } from "../withAuth";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { FormButtonTO } from "../FormButtonTO.jsx";
import { SelectPicker } from "../common/SelectPicker.js";
import { ScrollSelectPicker } from "../common/ScrollSelectPicker";
import { getFormattedNumber } from "../../tools/getFormattedNumber";
import { KiTable } from "../common/KiTable/KiTable.js";
import { KiTextField } from "../common/Input/KiTextField.js";
import { KiDropdown } from "../common/Input/KiDropdown";

import improvedDataLogo from "../../public/logo-small.png";

import { palette } from "../../styles/palette.js";
import { CustomButton } from "../common/Button/CustomButton.js";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";
import { NeutralizeCaPopup } from "./popups/NeutralizeCaPopup";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context";
import { getDateDeltaInMinutes } from "../../tools/date";
import { BlacklistCustomerGroupsPopup } from "./popups/BlacklistCustomerGroupsPopup.jsx";

const styles = theme => ({
  root: {
    width: "calc(100% - 68px - 80px)",
    padding: "0 68px",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    paddingTop: 25,
    color: palette.black
  },
  subtitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "400",
    marginBottom: "32px",
    color: palette.black,
  },
  ctas: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  ctasGroup: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 8,
  },
  improvedDataLogo: {
    width: 12,
    height: 12,
    marginRight: 4
  },
  search: {
    position: "relative",
    color: "#a5adb8",
    border: "1px solid " + palette.purple,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      color: "#949494",
      border: "1px solid " + palette.purple,
    },
    width: "100%",
  },
  statusIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: 1000,
    marginRight: 8,
  },
  greenIcon: {
    backgroundColor: "#b8e986"
  },
  redIcon: {
    backgroundColor: palette.accentRed200
  },
  inputInput: {
    color: palette.black,
    padding: "4px 16px 4px 40px",
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    height: "30px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 100,
      "&:focus": {
        width: 132,
      },
    }
  },
  selectedRowsToolbar: {
    backgroundColor: palette.primaryBlack,
    color: palette.primaryWhite,
    height: 64,
    padding: "0 32px",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: 16,
    }
  },
  cardContainer: {
    display: "flex",
    columnGap: "30px"

  },
  paperCard: {
    display: "flex",
    padding: "10px 25px",
    gridAutoFlow: "column",
    gridGap: "25px",
    alignItems: "center",
    whiteSpace: "pre",
    minWidth:"210px",
    width:"auto",
    justifyContent: "center",
  },
  bigPaperCard: {
    display: "flex",
    padding: "0px 25px",
    gridAutoFlow: "column",
    gridGap: "45px",
    alignItems: "center",
    whiteSpace: "pre",
    height:"100%"
  },
  numberPaperCard: {
    color: palette.black,
    fontSize: "36px",
    textAlign: "left",
    lineHeight: "31px",
    fontWeight: "300",
    marginTop: "12px",
    marginLeft: "25px",
  },
  textPaperCard: {
    fontSize: "13px",
    textAlign: "left",
    color: palette.darkgray,
  },
  dataPaperCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height:"80%",
    paddingTop:10,
    paddingBottom:10,
    width:"auto",
  },
  iconPaperCard: {
    color: palette.primaryYellow200,
    fontSize: "39px",
  },
  loading: {
    margin: "auto",
    color: palette.black
  },
  blacklistTitle: {
    marginBottom: "30px",
  },
  blacklistDropdown: {
    marginBottom: "15px",
  }
});

export const Customers = withAuth(
  withStyles(styles)(
    injectIntl(
      class Customers extends Component {
        constructor(props) {
          super(props);
          this.getPaginatedData = this.getPaginatedData.bind(this);
          this.blacklistCustomers = this.blacklistCustomers.bind(this);
          this.whitelistCustomers = this.whitelistCustomers.bind(this);
          this.exportContactsCSV = this.exportContactsCSV.bind(this);
          this.getMatchesSearch = this.getMatchesSearch.bind(this);
          this.changeSearch = this.changeSearch.bind(this);
          this.switchStatuses = this.switchStatuses.bind(this);
          this.switchStatusReasons = this.switchStatusReasons.bind(this);
          this.getStatusReason = this.getStatusReason.bind(this);
          this.getStatus = this.getStatus.bind(this);
          this.selectRows = this.selectRows.bind(this);
          this.onSelectAll = this.onSelectAll.bind(this);
          this.loadMoreData = this.loadMoreData.bind(this);
          this.resetData = this.resetData.bind(this);
          this.isFilteringByBlacklisted = this.isFilteringByBlacklisted.bind(this);
          this.reintegrateCa = this.reintegrateCa.bind(this);
          this.onOrder = this.onOrder.bind(this);
          this.syncBrevoMailchimp = this.syncBrevoMailchimp.bind(this);
          this.savePagination = this.savePagination.bind(this);
          this.getDistinctCustomerGroups = this.getDistinctCustomerGroups.bind(this);
          this.switchCustomerGroups = this.switchCustomerGroups.bind(this);
          this.openCustomerGroupPopup = this.openCustomerGroupPopup.bind(this);
          this.closeCustomerGroupPopup = this.closeCustomerGroupPopup.bind(this);
          this.handleOpenCategoriePopup = this.handleOpenCategoriePopup.bind(this);

          function dateSortFunction(prevValue, nextValue, orderType) {
            const formattedPrevValue = `${prevValue.slice(3, 5)}/${prevValue.slice(0, 2)}/${prevValue.slice(6, 10)}`;
            const formattedNextValue = `${nextValue.slice(3, 5)}/${nextValue.slice(0, 2)}/${nextValue.slice(6, 10)}`;

            if (orderType === "asc") {
              return (new Date(formattedPrevValue)).getTime() > (new Date(formattedNextValue)).getTime() ? 1 : -1;
            } else if (orderType === "desc") {
              return (new Date(formattedPrevValue)).getTime() > (new Date(formattedNextValue)).getTime() ? -1 : 1;
            }
          }

          const improvedDataLogoElement = <img src={improvedDataLogo} className={props.classes.improvedDataLogo} alt="improved data logo"></img>;

          this.state = {
            account: undefined,
            currentUser: undefined,
            search: "",
            customers: [],
            numberCustomers: "-",
            numberBlacklistedCustomer: "-",
            numberInvalidContact: "-",
            numberActivesCustomers: "-",
            statuses: [
              { isActive: false, key: "subscribed" },
              { isActive: false, key: "blacklisted" },
              { isActive: false, key: "invalidContact" },
            ],
            statusReasons: [],
            columns: [
              {
                key: "fullName",
                name: props.intl.messages["customers.table.head.fullName"],
                orderField: "firstname"
              },
              {
                key: "email",
                name: props.intl.messages["customers.table.head.email"],
                orderField: "email"
              },
              {
                key: "status",
                name: props.intl.messages["customers.table.head.status"],
              },
              {
                key: "statusReason",
                name: props.intl.messages["customers.table.head.statusReason"],
              },
              {
                key: "languageId",
                name: props.intl.messages["customers.table.head.languageId"],
              },
              {
                key: "default_customer_group",
                name: props.intl.messages["customers.table.head.group"],
                orderField: "default_customer_group"
              },
              {
                key: "gender",
                name: props.intl.messages["customers.table.head.gender"],
                startAdornment: improvedDataLogoElement,
              },
              {
                key: "segment",
                name: props.intl.messages["customers.table.head.segment"],
                startAdornment: improvedDataLogoElement,
                orderField: "segment_rfm"
              },
              {
                key: "ca",
                name: props.intl.messages["customers.table.head.ca"],
                startAdornment: improvedDataLogoElement,
                sortProperty: "rawValue",
                orderField: "ca"
              },
              {
                key: "sponsorshipStatus",
                name: props.intl.messages["customers.table.head.sponsorshipStatus"],
                startAdornment: improvedDataLogoElement,
              },
              {
                key: "lastContactDate",
                name: props.intl.messages["customers.table.head.lastContactDate"],
                sortFunction: dateSortFunction,
                startAdornment: improvedDataLogoElement,
                orderField: "recency"
              },
            ],
            selectedReason: [],
            selectedBlacklistReason: "",
            authorizedLanguages: ["fr", "en", "de", "es", "nl", "it", "pt"],
            whitelistPopupIsOpen: false,
            blacklistPopupIsOpen: false,
            noCms: false,
            emptyStateVariant: "",
            loadingCustomers: true,
            manualWhitelistingIsInProgress: false,
            manualBlacklistingIsInProgress: false,
            shouldShowButtons: false,
            limit: 3000,
            total: undefined,
            CMS: "",
            exportCustomersCSVLoading: false,
            shouldShowLanguageButton: false,
            neutralizeCaPopupIsOpen: false,
            showOutOfPackageFilter: false,
            order: null,
            shouldShowBrevoMailChimpSynchronizeButton: false,
            emailingPlatformsInAccount: [],
            customBlacklistReason: "",
            pagination: {
              key: 0,
              pageKey: 0,
              rowsPerPage: 10
            },
            customerGroups: [],
            blacklistCustomGroupPopupIsOpen: false,
            viewAllProductFromBlacklistCategoriesMode: false
          };

          this.onSearch$ = new Subject();
        }

        handleSubmitBlacklist() {
          EventSystem.publish("url_switch", "/blacklist?blacklistType=customers");
        }

        handleSubmitWhitelist() {
          EventSystem.publish("url_switch", "/whitelist?whitelistType=customers");
        }

        handleSubmitLangSettings() {
          EventSystem.publish("url_switch", "/email_lang_settings");
        }

        openCustomerGroupPopup() {
          this.setState({ blacklistCustomGroupPopupIsOpen: true });
        }

        closeCustomerGroupPopup(groupId){
          if (groupId) {
            const updatedGroups = this.state.customerGroups.map(group => {
              return { ...group, isActive: group.id === groupId };
            });
  
            this.setState({ customerGroups: updatedGroups, viewAllProductFromBlacklistCategoriesMode: true,  customers: [] }, () => {
              this.getPaginatedData();
              this.getAccountAmountOfContacts();
            });
          }

          this.setState({ blacklistCustomGroupPopupIsOpen: false });
        }

        async componentDidMount() {
          const {
            auth,
          } = this.props;
          const user = await auth.getUser();
          const { account, config } = AccountContextBehaviorSubject$.getValue();
          this.onSearch$.asObservable().pipe(
            debounceTime(300),
            distinctUntilChanged()
          ).subscribe(debounced => {
            this.setState({ customers: [], search: debounced }, () => this.resetData());
          });

          this.setState({ cmsConfig: config });

          if (!config?.customersGroupManagement) {
            this.setState({ columns: this.state.columns.filter(column => column.key !== "default_customer_group") });
          }

          localStorage.setItem("CMS", account.CMS);
          this.setState({ CMS: account.CMS, account: account, currentUser: user ? user.attributes.email : account.admin_user});

          if (account.CMS !== "magento" || (account.plug_version && !account.plug_version.startsWith("1"))) {
            this.setState({ shouldShowLanguageButton: true });
          }

          if (account.brevo || account.mailchimp) {
            const emailingPlatformsInAccount = [];
            if (account.brevo) {
              emailingPlatformsInAccount.push("Brevo");
            }
            if(account.mailchimp) {
              emailingPlatformsInAccount.push("Mailchimp");
            }
            
            this.setState({ shouldShowBrevoMailChimpSynchronizeButton: true, emailingPlatformsInAccount });
          }

          this.getAccountAmountOfContacts();
          this.getDistinctBlacklistReasons();
          if (config?.customersGroupManagement) {
            this.getDistinctCustomerGroups();
          }

          // adapt filter for shopify
          if (account.CMS !== "shopify") {
            const newStatuses = JSON.parse(JSON.stringify(this.state.statuses));
            //newStatuses.push({ isActive: true, key: "blocked" });
            this.setState({ statuses: newStatuses});
          }

          const authorizedLanguages = account.email_lang_settings.map(setting => setting.contact_lang);
          this.setState({ authorizedLanguages }, () => {
            this.getPaginatedData()
              .then((customers) => {
                const { account } = AccountContextBehaviorSubject$.getValue();
                this.setEmptyStateVariant(customers, account);
              });
          });
        }

        componentWillUnmount() {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }

        setEmptyStateVariant(customers, account) {
          if (account.step_register > 2) {
            if (!customers?.length) {
              this.setState({ emptyStateVariant: "syncActiveCustomers" });
              this.setState({ noCms: true });
            } else {
              this.setState({ noCms: false, shouldShowButtons: true });
            }
          } else {
            this.setState({ noCms: true });
            this.setState({ emptyStateVariant: "noCmsCustomers" });
          }
        }

        async getPaginatedData(page = 0) {
          const {
            auth,
            intl,
          } = this.props;

          this.setState({ loadingCustomers: true });
          const { account } = AccountContextBehaviorSubject$.getValue();

          try {
            let url = `/api/data/getPagingCustomers?accountId=${account._id}&limit=${this.state.limit}&page=${page || 0}&searchText=${this.state.search}`;

            // filter by status
            const activeStatus = this.getActiveFilters(this.state.statuses);
            if (activeStatus.length !== this.state.statuses.length) {
              activeStatus.forEach((activeFilter, index) => {
                if(activeFilter.key === "subscribed") {
                  url += "&statuses=subscribed";
                }
                if(activeFilter.key === "blacklisted") {
                  url += "&statuses=blacklisted";
                }
                if(activeFilter.key === "invalidContact") {
                  url += "&statuses=invalidContact";
                }
              });
            }
            
            // filter by statusreasons
            this.getActiveFilters(this.state.statusReasons).forEach((activeFilter, index) => {
              const containsBlacklisted = activeStatus.some(item => item.key === "blacklisted");
              const containsInvalid = activeStatus.some(item => item.key === "invalidContact");

              if (containsBlacklisted && !activeFilter.key.includes("invalid_contact")) {
                url += `&statusReasons=${activeFilter.key}`;
              }
              if (containsInvalid && activeFilter.key.includes("invalid_contact")) {
                url += `&statusReasons=${activeFilter.key}`;
              }
            });

            // filter by customer groups
            this.getActiveFilters(this.state.customerGroups).forEach((activeFilter, index) => {
              url += `&customerGroups=${activeFilter.id}`;
            });

            if (this.state.order) {
              const orderField = this.state.columns.find(({ key }) => key === this.state.order.columnKey)?.orderField;
              url += `&orderField=${orderField}&orderType=${this.state.order.type}`;
            }
            
            const result = (await auth.fetch(url, { method: "GET" }));
            const customers = result.customers;
            const statusReasons = JSON.parse(JSON.stringify(this.state.statusReasons));
            const formattedCustomers = customers.map(customer => {

              const languageIsValid = !!this.state.authorizedLanguages.find(authorizedLanguage => authorizedLanguage === customer.id_lang);
              // status and status reason priority: blacklisted -> blocked -> null
              const status = this.getStatus(customer, languageIsValid);
              const statusReason = this.getStatusReason(customer, languageIsValid);

              return {
                id: customer.id_prestashop,
                fullName: (
                  ((customer.firstname && customer.firstname !== " ") ? (customer.firstname[0].toUpperCase() + customer.firstname.toLowerCase().slice(1, customer.firstname.length)) : "") +
                    " " +
                    ((customer.lastname && customer.lastname !== " ") ? customer.lastname.toUpperCase() : "")
                ),
                email: customer.email,
                status: status,
                statusReason: statusReason,
                languageId: intl.messages[`customers.table.row.language.${customer.id_lang || "null"}`],
                gender: this.getGender(customer.id_gender),
                segment: customer.customer_segment_v2 ? customer.customer_segment_v2 :intl.messages[`customers.table.row.segment.${customer.customer_segment_v2 || "null"}`],
                lastContactDate: moment(customer.recency.substring(0, 10)).format("DD/MM/YYYY"),
                sponsorshipStatus: {
                  isSolicited: customer.is_solicited_for_sponsorship,
                  isGodson: customer.is_godson,
                  isSponsor: customer.is_sponsor,
                },
                isBlocked: !languageIsValid,
                isBlacklisted: !!customer.date_blacklist,
                isSelected: false,
                ca: account.customersBlacklistCA.includes(customer.id_prestashop) ? "ignored" : customer.ca,
                default_customer_group: customer.default_customer_group,
              };
            });

            const newStatuses = JSON.parse(JSON.stringify(this.state.statuses));
            if (!newStatuses.find(status => status.key === "hors-forfait") && result.totalOutOfPackage) {
              newStatuses.push({ isActive: true, key: "hors-forfait" });
            }
            this.setState(prev => ({
              statusReasons: statusReasons,
              customers: [...this.state.customers, ...formattedCustomers],
              total: result.total,
              statuses: newStatuses
            }), () => {
              this.setState({ loadingCustomers: false });
            });

            return formattedCustomers;
          } catch (error) {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["customers.loading.error"]);
            this.setState({ loadingCustomers: false });
          }
        }

        async loadMoreData(page) {
          this.getPaginatedData(page);
        }

        async resetData() {
          const updatedPagination = Object.assign(this.state.pagination, { key: 0, pageKey: 0 });
          this.setState({ customers: [], pagination: updatedPagination }, () => {
            this.getPaginatedData();
            this.getAccountAmountOfContacts();
          });
        }

        getActiveFilters(filterObject) {
          if (!filterObject) { return; }
          return filterObject
            .filter(x => x.isActive);
        }

        getStatus(customer, languageIsValid) {
          if (languageIsValid === false) {
            return "blacklisted";
          }
          if (customer.blacklist_reason === null) {
            return "subscribed";
          }
          if (customer.blacklist_reason.includes("invalid_contact")) {
            return "invalidContact";
          }
          if (customer.blacklist_reason.includes("blacklist")) {
            return "blacklisted";
          }
          if (customer.blacklist_reason.includes("customer_group")) {
            return "blacklisted";
          }
        }

        getStatusReason(customer, languageIsValid) {
          if(!languageIsValid){
            return "blacklist.LangBlock";
          }
          if(customer.blacklist_reason) {
            if(customer.blacklist_reason.includes("reasonName")){
              const reasonObj = JSON.parse(customer.reason_blacklist);
              if(reasonObj.reasonName === "blacklist.Custom"){
                return reasonObj.customtext;
              }
              return reasonObj.reasonName;
            }
            return customer.blacklist_reason;
          }
          return "-";
        }

        getGender(genderId) {
          const { intl } = this.props;

          if (genderId === "1") return intl.messages["customers.table.row.gender.male"];
          if (genderId === "2") return intl.messages["customers.table.row.gender.female"];
          return intl.messages["customers.table.row.gender.unknown"];
        }

        changeSearch(event) {
          const search = event.target.value.toLowerCase();
          this.setState({ search: search, pageKey: 0 });
          this.onSearch$.next(search);
        }

        switchStatuses(name, value) {
          const { intl } = this.props;

          const newStatuses = JSON.parse(JSON.stringify(this.state.statuses));

          newStatuses.forEach(newStatus => {
            newStatus.isActive = value.includes(intl.messages[`customers.filters.status.${newStatus.key}`]);
          });

          this.setState({ statuses: newStatuses, customers: [] }, () => this.resetData());
        }

        switchStatusReasons(name, value) {
          const {
            intl,
          } = this.props;

          const newStatusReasons = JSON.parse(JSON.stringify(this.state.statusReasons));

          newStatusReasons.forEach(newStatusReason => {
            newStatusReason.isActive = value.includes(intl.messages[`customers.filters.statusReason.${newStatusReason.key}`] || newStatusReason.key);
          });
          this.setState({ statusReasons: newStatusReasons, customers: [] }, () => this.getPaginatedData());
        }

        switchCustomerGroups(id) {
          const updatedGroups = this.state.customerGroups.map(group => 
            group.id === id ? { ...group, isActive: !group.isActive} : group
          );
          this.setState({ customerGroups: updatedGroups, customers: [], pagination: { key: 0, pageKey: 0, rowsPerPage: 10 } }, () => this.getPaginatedData());
        }

        async blacklistCustomers() {
          const { intl, auth } = this.props;

          this.setState({ manualBlacklistingIsInProgress: true });
          try {
            const { account } = AccountContextBehaviorSubject$.getValue();

            const emails = this.state.customers.filter(customer => customer.isSelected).map(customer => customer.email);
            await auth.fetch(`/api/data/blacklistCustomers?accountId=${account._id}`, { 
              method: "POST",
              body: JSON.stringify({ 
                emails : emails, 
                reason: {
                  reasonName: "blacklist."+this.state.selectedBlacklistReason, 
                  source: "webapp", 
                  adminUser: this.state.currentUser, 
                  customtext:this.state.customBlacklistReason 
                }
              }),
            })
              .then(() => {
                this.setState({ customers: [], customBlacklistReason: "",selectedBlacklistReason: "" }, () => {
                  this.getPaginatedData(0);
                  this.getAccountAmountOfContacts();
                });
                this.setState({
                  manualBlacklistingIsInProgress: false,
                });
                EventSystem.newNotification( "notification.action", this.props.intl.messages["customers.blacklist.success"]);
              });
          } catch (e) {
            EventSystem.newNotification("notification.error", intl.messages["customers.blacklist.error"]);
          }
          this.getDistinctBlacklistReasons();
        }

        async whitelistCustomers() {
          const {
            intl,
          } = this.props;

          await new Promise((resolve, reject) => this.setState({ manualWhitelistingIsInProgress: true }, resolve));
          const { account } = AccountContextBehaviorSubject$.getValue();

          const options = {
            method: "POST",
            body: JSON.stringify({
              emails: this.state.customers
                .filter(customer => customer.isSelected)
                .map(customer => customer.email)
            })
          };
          try {
            await this.props.auth.fetch(`/api/data/whitelistCustomers?accountId=${account._id}`, options);
            this.setState({ customers: [] }, () => {
              this.getPaginatedData(0);
              this.getAccountAmountOfContacts();
            });
            EventSystem.newNotification("notification.action", intl.messages["customers.whitelist.success"]);
          } catch (error) {
            EventSystem.newNotification("notification.action", intl.messages["customers.whitelist.error"]);
          }

          this.setState({
            manualWhitelistingIsInProgress: false,
            whitelistPopupIsOpen: false,
          });
          this.getDistinctBlacklistReasons();
        }

        async exportContactsCSV() {
          const { auth, intl } = this.props;
          this.setState({ exportCustomersCSVLoading: true });
          try {
            const header = [
              { id: "id", title: intl.messages["customers.table.head.id"] },
              { id: "fullName", title: intl.messages["customers.table.head.fullName"] },
              { id: "email", title: intl.messages["customers.table.head.email"] },
              { id: "status", title: intl.messages["customers.table.head.status"] },
              { id: "statusReason", title: intl.messages["customers.table.head.statusReason"] },
              { id: "languageId", title: intl.messages["customers.table.head.languageId"] },
              { id: "defaultCustomerGroup", title: intl.messages["customers.table.head.group"] },
              { id: "gender", title: intl.messages["customers.table.head.gender"] },
              { id: "segment", title: intl.messages["customers.table.head.segment"] },
              { id: "sponsorshipStatus", title: intl.messages["customers.table.head.sponsorshipStatus"] },
              { id: "lastContactDate", title: intl.messages["customers.table.head.lastContactDate"] },
            ];
            
            const statusWordings = {};
            const selectedStatuses = this.state.statuses.filter(status => status.isActive).map(status => status.key);
            for(const status of this.state.statuses) {
              statusWordings[status.key] = intl.messages[`customers.filters.status.${status.key}`];
            }
            
            const statusReasonsWordings = {};
            for(const statusReason of this.state.statusReasons) {
              statusReasonsWordings[statusReason.key.toLowerCase()] = intl.messages[`customers.filters.statusReason.${statusReason.key}`];
            }
            
            const sponsorshipWordings = {
              isSolicited : intl.messages["customers.filters.statusReason.isSolicited"],
              isSponsor: intl.messages["customers.filters.statusReason.isSponsor"],
              isGodson: intl.messages["customers.filters.statusReason.isGodson"]
            };
            
            const genderWordings = {
              "1": intl.messages["customers.table.row.gender.male"],
              "2": intl.messages["customers.table.row.gender.female"]
            };

            const sendExportViaEmail = this.state.total >= 10_000;

            let orderField = undefined;
            let orderType = undefined;
            if (this.state.order) {
              orderField = this.state.columns.find(({ key }) => key === this.state.order.columnKey)?.orderField;
              orderType = this.state.order.type;
            }

            const response = await auth.fetch("/api/data/exportCustomersCSV", {
              method: "POST",
              body: JSON.stringify({
                header,
                statusWordings,
                statusReasonsWordings,
                sponsorshipWordings,
                genderWordings,
                searchText: this.state.search,
                statuses: selectedStatuses,
                statusReasons: this.state.statusReasons.filter(statusReason => statusReason.isActive).map(statusReason => statusReason.key),
                customerGroups: this.state.customerGroups.filter(customerGroup => customerGroup.isActive).map(customerGroup => customerGroup.id),
                sendViaEmail: sendExportViaEmail,
                locale: intl.locale,
                orderField,
                orderType
              }),
            }, {
              rawResponse: true
            });

            if(sendExportViaEmail) {
              const user = await auth.getUser();
              EventSystem.newNotification(
                "notification.action",
                intl.formatMessage({ id: "csv.export.sendViaEmail" }, { email: user.attributes.email })
              );
            } else {
              const blob = await response.blob();
              const fileUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = fileUrl;
              link.download = "contacts_export.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } catch (error) {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["error.main_error"]);
          }

          this.setState({ exportCustomersCSVLoading: false });
        }

        async syncBrevoMailchimp() {
          const lastStoredClickDate = localStorage.getItem("last_synchronize_button_click_date");
          if (!lastStoredClickDate || (lastStoredClickDate && getDateDeltaInMinutes(new Date(), new Date(lastStoredClickDate)) > 10)) {
            this.props.auth.fetch("/api/account_management/syncBrevoMailchimp", {
              method: "GET",
            }).then(() => {
              localStorage.setItem("last_synchronize_button_click_date", (new Date()).toISOString());
            });
          }

          EventSystem.newNotification(
            "notification.action",
            this.props.intl.messages["integration.cmsCard.synchrone.success"]
          );
        }

        getSponsorshipStatusWording(sponsorshipStatus) {
          const {
            intl,
          } = this.props;

          if (sponsorshipStatus.isGodson) return intl.messages["customers.filters.sponsorshipStatus.isGodson"];
          if (sponsorshipStatus.isSponsor) return intl.messages["customers.filters.sponsorshipStatus.isSponsor"];
          if (sponsorshipStatus.isSolicited) return intl.messages["customers.filters.sponsorshipStatus.isSolicited"];
          return intl.messages["customers.filters.sponsorshipStatus.null"];
        }

        savePagination(pagination) {
          if (JSON.stringify(pagination) !== JSON.stringify(this.state.pagination)) {
            this.setState({ pagination });
          }
        };

        getRows(customers) {
          const {
            classes,
            intl,
          } = this.props;

          return customers ? customers.map(customer => {
            const statusIcon = <div className={`${classes.statusIcon} ${customer.status === "subscribed" ? classes.greenIcon : classes.redIcon}`}></div>;

            const getColumnKey = (columnIndexKey) => this.state.columns[columnIndexKey].key;
            const getRowValue = (columnIndexKey) => customer[this.state.columns[columnIndexKey].key];

            const getCaRawValue = (ca) => {
              if (ca === null) {
                return 0;
              } else if (ca === "ignored") {
                return -1;
              } else {
                return ca;
              }
            };

            return {
              id: customer.id,
              isSelected: customer.isSelected,
              values: this.state.cmsConfig.customersGroupManagement ? {
                [getColumnKey(0)]: { displayedValue: getRowValue(0) },
                [getColumnKey(1)]: { displayedValue: getRowValue(1) },
                [getColumnKey(2)]: { displayedValue: intl.messages[`customers.filters.status.${getRowValue(2)}`], startAdornment: statusIcon },
                [getColumnKey(3)]: { displayedValue: intl.messages[`customers.filters.statusReason.${getRowValue(3)}`] || getRowValue(3) || " - " },
                [getColumnKey(4)]: { displayedValue: getRowValue(4) },
                [getColumnKey(5)]: { displayedValue: getRowValue(5) },

                [getColumnKey(6)]: { displayedValue: getRowValue(6) },
                [getColumnKey(7)]: { displayedValue: getRowValue(7) },
                [getColumnKey(8)]: { 
                  rawValue: getCaRawValue(getRowValue(8)),
                  displayedValue: getRowValue(8) 
                },
                [getColumnKey(9)]: { displayedValue: this.getSponsorshipStatusWording(getRowValue(9)) },
                [getColumnKey(10)]: { displayedValue: getRowValue(10) },
              } : {
                [getColumnKey(0)]: { displayedValue: getRowValue(0) },
                [getColumnKey(1)]: { displayedValue: getRowValue(1) },
                [getColumnKey(2)]: { displayedValue: intl.messages[`customers.filters.status.${getRowValue(2)}`], startAdornment: statusIcon },
                [getColumnKey(3)]: { displayedValue: intl.messages[`customers.filters.statusReason.${getRowValue(3)}`] || getRowValue(3) || " - " },
                [getColumnKey(4)]: { displayedValue: getRowValue(4) },
                [getColumnKey(5)]: { displayedValue: getRowValue(5) },
                [getColumnKey(6)]: { displayedValue: getRowValue(6) },
                [getColumnKey(7)]: { 
                  rawValue: getCaRawValue(getRowValue(7)),
                  displayedValue: getRowValue(7) 
                },
                [getColumnKey(8)]: { displayedValue: this.getSponsorshipStatusWording(getRowValue(8)) },
                [getColumnKey(9)]: { displayedValue: getRowValue(9) },
              }
            };
          }) : undefined;
        }

        getMatchesSearch(value) {
          if (this.state.search) {
            return !!value && value.toLowerCase().includes(this.state.search.toLowerCase());
          } else {
            return true;
          }
        }

        selectRows(id) {
          const updatedRows = this.state.customers.map(row => row.id === id ? { ...row, isSelected: !row.isSelected} : row);

          this.setState({
            customers: updatedRows
          });
        }

        onSelectAll(ids) {
          const updatedRows = this.state.customers.map(row => ({ ...row, isSelected: ids.length ? true : false}));
          this.setState({ customers: updatedRows });
        };

        async getAccountAmountOfContacts() {
          const urlAccountAmountOfContacts = "/api/data/getAccountAmountOfContacts";
          const option = { method: "GET" };

          const accountContactsAndActifs = await this.props.auth.fetch(urlAccountAmountOfContacts, option);
          this.setState({ numberCustomers: accountContactsAndActifs.contacts, numberActivesCustomers: accountContactsAndActifs.actifs , numberBlacklistedCustomer: accountContactsAndActifs.blacklisted, numberInvalidContact: accountContactsAndActifs.invalids});
        }


        async getDistinctBlacklistReasons() {
          const { intl } = this.props;
          const url = "/api/data/getDistinctBlacklistReasons";
          const option = { method: "GET" };
          const blacklistReasons = await this.props.auth.fetch(url, option);
          const sameReasonsWording = [  
            "invalid_contact.name_with_forbidden_words", 
            "invalid_contact.not_reachable_domain",
            "invalid_contact.domain_blacklisted",
            "invalid_contact.invalid_email",
            "invalid_contact.marketplace",
            "invalid_contact.automatic",
            "invalid_contact.bl_from_cleaning",
          ];

          const statusReasons = [];
          for(const blacklistReason of blacklistReasons) {
            let key = blacklistReason;
            if(blacklistReason.includes("reasonName")){
              const reasonObj = JSON.parse(blacklistReason);
              // ADD CUSTOM WORDING IN DROPDWN LIST
              key = reasonObj.reasonName === "blacklist.Custom" ? reasonObj.customtext : reasonObj.reasonName;
            }
            const newReason = { isActive: true, key: key, wording: intl.messages[`customers.filters.statusReason.${key}`] };
            const isSameWording = sameReasonsWording.some(item => item === key);
            //CHECK IF REASON WORDING ALREADY IN DROPDOWN LIST
            if(isSameWording) {
              statusReasons.push({ isActive: true, key: key, wording: intl.messages[`customers.filters.statusReason.automatic`] });
              const wordingToCheck = intl.messages["customers.filters.statusReason.invalid_contact.invalid_email"];
              const wordingExists = statusReasons.some(item => item.wording === wordingToCheck);
              if(!wordingExists) { statusReasons.push(newReason); }
            } else {
              const reasonExists = statusReasons.some(reason => reason.key === key);
              if(!reasonExists) {
                statusReasons.push(newReason);
              }
            }

          };
          this.setState({ statusReasons });
        }

        async getDistinctCustomerGroups() {
          this.props.auth.fetch("/api/data/getDistinctCustomersGroup", { method: "GET" })
            .then(customerGroups => {
              customerGroups = customerGroups.map(group => {
                return {
                  ...group,
                  isActive: false
                };
              });

              this.setState({ customerGroups });
            });
        }

        handleOpenCategoriePopup() {
          this.setState({ 
            blacklistCustomGroupPopupIsOpen: true, 
            viewAllProductFromBlacklistCategoriesMode: false, 
            customerGroups: this.state.customerGroups.map(group => ({ ...group, isActive: false })) 
          }, () => this.getPaginatedData());
        }

        isFilteringByBlacklisted() {
          const activeFilters = this.getActiveFilters(this.state.statuses);
          return activeFilters.length === 1 && activeFilters[0].key === "blacklisted";
        }

        isFilteringByInvalid() {
          const activeFilters = this.getActiveFilters(this.state.statuses);
          return activeFilters.length === 1 && activeFilters[0].key === "invalidContact";
        }

        async reintegrateCa() {
          const customerIds = this.state.customers
            .filter(customer => customer.isSelected)
            .map(customer => customer.id);

          await this.props.auth.fetch("/api/account_management/reintegrateCustomersCa", {
            method: "POST",
            body: JSON.stringify({ 
              customerIds,
            }),
          });
          await reloadAccountContext();
          EventSystem.newNotification("notification.action", <FormattedMessage id="customers.reintegrateCa.notification" values={{itemCount: customerIds.length}} />);
          this.setState({ customers: [] }, () => {
            this.getPaginatedData(0);
            this.getAccountAmountOfContacts();
          });
        }

        onOrder(newOrder) {
          this.setState({ order: newOrder, customers: [] }, () => {
            this.getPaginatedData();
          });
        }

        render() {
          const {
            classes,
            intl,
          } = this.props;

          const filteredCustomers = this.state.customers;

          const rows = this.getRows(filteredCustomers);
          const selectedRowsCount = rows ? rows.filter(row => row.isSelected).length : 0;
          
          const selectedCustomers = filteredCustomers.filter(customer => customer.isSelected);
          const hasBlacklistedCustomer = selectedCustomers.some(customer => (customer.isBlacklisted === true && customer.status === "blacklisted"));
          const hasSubscribedCustomer = selectedCustomers.some(customer => (customer.isBlacklisted === false && customer.isBlocked === false && customer.status === "subscribed"));
          const hasInvalidCustomer = selectedCustomers.some(customer => (customer.isBlacklisted === true && customer.statusReason.includes("invalid_contact") ));

          return (
            <div className={classes.root}>
              <div className={classes.header}>
                <div className={classes.title}>
                  <FormattedMessage id="data.customers" defaultMessage="Smart CRM" />
                </div>
                <div className={classes.subtitle}>
                  <Translation id="customers.intro1" defaultMessage="" />
                </div>

                <div className={classes.cardContainer} style={{ marginBottom: "40px" }}>
                  <StatsCard
                    icon={"users"}
                    tooltip={intl.messages["customers.base.tooltip.contacts"]}
                    number={this.state.numberCustomers ? this.state.numberCustomers : "-"}
                    text={intl.messages["customers.base.contacts"]}
                    loading={this.state.loadingCustomers}
                  >
                  </StatsCard>
                  
                  <BigStatsCard
                    icon1={"grin-hearts"}
                    icon2={"user-times"}
                    icon3={"lock"}
                    number1={this.state.numberActivesCustomers ? this.state.numberActivesCustomers : "-"}
                    number2={this.state.numberInvalidContact ? this.state.numberInvalidContact : "-"}
                    number3={this.state.numberBlacklistedCustomer ? this.state.numberBlacklistedCustomer : "-"}
                    text1={intl.messages["customers.base.subscribers"]}
                    text2={intl.messages["customers.base.invalid.contacts"]}
                    text3={intl.messages["customers.base.blacklisted"]} 
                    loading={this.state.loadingCustomers}
                  >
                  </BigStatsCard>
                  
                </div>

                {
                  selectedRowsCount > 0
                    ? (
                      <SelectedRowsToolbar
                        selectedRowsCount={selectedRowsCount}
                        hasBlacklistedCustomer={hasBlacklistedCustomer}
                        hasSubscribedCustomer={hasSubscribedCustomer}
                        hasInvalidCustomer={hasInvalidCustomer}
                        blacklistCustomers={this.blacklistCustomers}
                        openWhitelistPopup={() => this.setState({ whitelistPopupIsOpen: true })}
                        openBlacklistPopup={() => this.setState({ blacklistPopupIsOpen: true })}
                        openNeutralizeCaPopup={() => this.setState({
                          neutralizeCaPopupIsOpen: true 
                        })}
                        manualBlacklistingIsInProgress={this.state.manualBlacklistingIsInProgress}
                        reintegrateCa={this.reintegrateCa}
                        showNeutralizeCaButton={
                          selectedCustomers
                            .some(customer => customer.ca !== "ignored")
                        }
                        showReintegrateCaButton={
                          selectedCustomers
                            .some(customer => customer.ca === "ignored")
                        }
                      />
                    )
                    : (
                      <Ctas
                        search={this.state.search}
                        statuses={this.state.statuses}
                        switchStatuses={this.switchStatuses}
                        statusReasons={this.state.statusReasons}
                        switchStatusReasons={this.switchStatusReasons}
                        changeSearch={this.changeSearch}
                        exportContacts={this.exportContactsCSV}
                        handleSubmitBlacklist={this.handleSubmitBlacklist}
                        handleSubmitWhitelist={this.handleSubmitWhitelist}
                        handleSubmitLangSettings={this.handleSubmitLangSettings}
                        openCustomerGroupPopup={this.openCustomerGroupPopup}
                        noCms={this.state.noCms}
                        shouldShowButtons={this.state.shouldShowButtons}
                        exportCustomersCSVLoading={this.state.exportCustomersCSVLoading}
                        isFilteringByBlacklisted={this.isFilteringByBlacklisted()}
                        isFilteringByInvalid={this.isFilteringByInvalid()}
                        shouldShowLanguageButton={this.state.shouldShowLanguageButton}
                        syncBrevoMailchimp={this.syncBrevoMailchimp}
                        shouldShowBrevoMailChimpSynchronizeButton={this.state.shouldShowBrevoMailChimpSynchronizeButton}
                        emailingPlatformsInAccount={this.state.emailingPlatformsInAccount}
                        loadingCustomers={this.state.loadingCustomers}
                        cmsConfig={this.state.cmsConfig}
                        customerGroups={this.state.customerGroups}
                        switchCustomerGroups={this.switchCustomerGroups}
                      />
                    )
                }
              </div>

              <KiTable
                columns={this.state.columns}
                rows={this.state.noCms ? null : rows}
                onSelect={this.selectRows}
                onSelectAll={this.onSelectAll}
                onOrder={this.onOrder}
                emptyStateVariant={this.state.emptyStateVariant}
                noCms={this.state.noCms}
                total={this.state.total}
                loadMoreData={this.loadMoreData}
                loading={this.state.loadingCustomers}
                resetData={this.resetData}
                account={this.state.account}
                paginationType="item"
                savePagination={this.savePagination}
                pagination={this.state.pagination}
                viewAllProductFromBlacklistCategorieMode={this.state.viewAllProductFromBlacklistCategoriesMode}
                onBackButtonClick={this.handleOpenCategoriePopup}
              ></KiTable>

              {/* WHITE LIST POPUP */}
              <Dialog
                isOpen={this.state.whitelistPopupIsOpen}
                open={() => this.setState({ whitelistPopupIsOpen: true })}
                close={() => this.setState({ whitelistPopupIsOpen: false })}
                title={intl.messages["customers.whitelist.title"]}
                buttonsProps={
                  [
                    {
                      label: intl.messages["customers.whitelist.cancel"],
                      onClick: () => this.setState({ whitelistPopupIsOpen: false }),
                      type: "secondary",
                    },
                    {
                      label: intl.messages["customers.whitelist.submit"],
                      onClick: async () => { await this.whitelistCustomers(); this.setState({ whitelistPopupIsOpen: false }); },
                      type: "primary",
                      loading: this.state.manualWhitelistingIsInProgress,
                    },
                  ]
                }
              >
                <Translation id="customers.whitelist.description" />
              </Dialog>

              {/* BLACK LIST POPUP */}
              <Dialog
                isOpen={this.state.blacklistPopupIsOpen}
                open={() => this.setState({ blacklistPopupIsOpen: true })}
                close={() => this.setState({ blacklistPopupIsOpen: false })}
                PaperProps={{ style: { borderRadius: "30px", textAlign: "center", width:"600px" } }}
                buttonsProps={
                  [
                    {
                      label: intl.messages["customers.whitelist.cancel"],
                      onClick: () => this.setState({ blacklistPopupIsOpen: false }),
                      type: "secondary",
                    },
                    {
                      label: intl.messages["customers.blacklist.button"],
                      onClick: async () => { await this.blacklistCustomers(); this.setState({ blacklistPopupIsOpen: false }); },
                      type: "primary",
                      disabled: this.state.selectedBlacklistReason === "" || (this.state.selectedBlacklistReason === "Custom" && this.state.customBlacklistReason === ""),
                      loading: this.state.manualWhitelistingIsInProgress,
                    },
                  ]
                }
              >

                <div className={`display3 ${classes.blacklistDropdown}`} >
                  <Translation id="customers.blacklist.title" />
                </div>
                <div className={classes.blacklistTitle} >
                  <Translation id="customers.blacklist.description" />
                </div>

                <div className={classes.blacklistDropdown}>
                  <KiDropdown
                    placeholder={intl.messages["editprofile.button.select"]}
                    value={this.state.selectedBlacklistReason}
                    options={[
                      { value: "SubscriptionAjust", label: intl.messages["customers.filters.statusReason.blacklist.SubscriptionAjust"], },
                      { value: "CustomerRequest", label: intl.messages["customers.filters.statusReason.blacklist.CustomerRequest"], },
                      { value: "Custom", label: intl.messages["customers.filters.statusReason.blacklist.Custom"], },
                    ]}
                    onChange={(event) => this.setState({ selectedBlacklistReason: event })}
                  />
                </div>
                

                {
                  this.state.selectedBlacklistReason === "Custom" &&
                    <KiTextField
                      placeholder={intl.messages["customers.filters.custom.placeholder"]}
                      //textAlign="center"
                      type="whiteBg"
                      name="custom_blacklist_reason"
                      handleChange={event => this.setState({ customBlacklistReason: event.target.value })}
                    />
                }
                
              </Dialog>


              <NeutralizeCaPopup 
                isOpen={this.state.neutralizeCaPopupIsOpen}
                close={() => this.setState({ neutralizeCaPopupIsOpen: false })}
                customerIds={
                  this.state.customers
                    .filter(customer => customer.isSelected)
                    .map(customer => customer.id)
                }
                onNeutralize={() => {
                  EventSystem.newNotification("notification.action", intl.messages["customers.neutralizeCa.notification"]);
                  this.setState({
                    customers: [], 
                    neutralizeCaPopupIsOpen: false 
                  }, () => {
                    this.getPaginatedData(0);
                    this.getAccountAmountOfContacts();
                  });
                }}
              />

              <BlacklistCustomerGroupsPopup
                isOpen={this.state.blacklistCustomGroupPopupIsOpen}
                close={this.closeCustomerGroupPopup}
                resetData={this.resetData}
              >
              </BlacklistCustomerGroupsPopup>
            </div>
          );
        }
      }
    )
  )
);

const SelectedRowsToolbar = withAuth(withStyles(styles)(injectIntl(class extends Component {
  render() {
    const {
      intl,
      classes,
      selectedRowsCount,
      hasBlacklistedCustomer,
      hasSubscribedCustomer,
      openWhitelistPopup,
      openBlacklistPopup,
      manualBlacklistingIsInProgress,
      openNeutralizeCaPopup,
      showNeutralizeCaButton,
      showReintegrateCaButton,
      reintegrateCa,
    } = this.props;


    return (
      <div className={classes.selectedRowsToolbar}>
        <p>{selectedRowsCount} {intl.messages["customers.selected"]}</p>
        {
          // hasInvalidCustomer &&
          
          hasSubscribedCustomer && 
          <CustomButton size={"md"} loading={manualBlacklistingIsInProgress} onClick={openBlacklistPopup} >
            <p>{intl.messages["customers.blacklist.button"]}</p>
          </CustomButton>
        }
        {
          hasBlacklistedCustomer && 
          <CustomButton size={"md"} onClick={openWhitelistPopup}>
            <p>{intl.messages["customers.whitelist.button"]}</p>
          </CustomButton>
        }
        {
          (hasSubscribedCustomer && showNeutralizeCaButton) && 
            <CustomButton size={"md"} onClick={openNeutralizeCaPopup}>
              <p>{intl.messages["customers.neutralizeCa.button"]}</p>
            </CustomButton>
        }
        {
          showReintegrateCaButton && 
          <CustomButton size={"md"} onClick={reintegrateCa}>
            <p>{intl.messages["customers.ReintegrateCa.button"]}</p>
          </CustomButton>
        
        }
      </div>
    );
  }
})));

const Ctas = withAuth(withStyles(styles)(injectIntl(class extends Component {
  render() {
    const {
      classes,
      intl,
      statuses,
      switchStatuses,
      statusReasons,
      switchStatusReasons,
      changeSearch,
      exportContacts,
      handleSubmitBlacklist,
      handleSubmitWhitelist,
      handleSubmitLangSettings,
      shouldShowButtons,
      exportCustomersCSVLoading,
      isFilteringByBlacklisted,
      isFilteringByInvalid,
      shouldShowLanguageButton,
      syncBrevoMailchimp,
      shouldShowBrevoMailChimpSynchronizeButton,
      emailingPlatformsInAccount,
      loadingCustomers,
      cmsConfig,
      customerGroups,
      switchCustomerGroups,
      openCustomerGroupPopup
    } = this.props;

    const switchAction = (action) => {
      switch (action) {
        case "langSetting":
          handleSubmitLangSettings();
          break;
        case "whitelist":
          handleSubmitWhitelist();
          break;
        case "blacklist":
          handleSubmitBlacklist();
          break;
        case "groupSetting":
          openCustomerGroupPopup();
      }
    };

    const statusNames = statuses.map(status => intl.messages[`customers.filters.status.${status.key}`]);
    const selectedStatusNames = statuses.filter(status => status.isActive).map(status => intl.messages[`customers.filters.status.${status.key}`] || status.key);
    const blackListStatusReasonNames = statusReasons.filter(item => !item.key.includes("invalid")).map(statusReason => intl.messages[`customers.filters.statusReason.${statusReason.key}`] || statusReason.key);
    const invalidStatusReasonNames = statusReasons.filter(item => item.key.includes("invalid")).map(statusReason => intl.messages[`customers.filters.statusReason.${statusReason.key}`] || statusReason.key);
    const activeCustomerGroups = customerGroups.filter(category => category.isActive);
    const fullInvalidStatusReasonNames = statusReasons.filter(item => item.key.includes("invalid")).map(statusReason => intl.messages[`customers.filters.statusReason.${statusReason.key}`] || statusReason.key);
    const fullSelectedStatusReasonNames = statusReasons.filter(statusReason => statusReason.isActive).map(statusReason => intl.messages[`customers.filters.statusReason.${statusReason.key}`] || statusReason.key);
    // deleting duplicates key for same wording reason
    const filteredInvalidStatusReasonNames = [...new Set(fullInvalidStatusReasonNames)];  
    const filteredSelectedStatusReasonNames = [...new Set(fullSelectedStatusReasonNames)];
  
    return (
      <div className={classes.ctas}>
        <div className={classes.ctasGroup}>
          <div className={classes.select}>
            {
              <SelectPicker
                disabled={loadingCustomers}
                selectList={statusNames}
                selected={selectedStatusNames}
                action={switchStatuses}
                displayName={intl.messages["customers.filters.status.title"]}
              />
            }
          </div>
          <div className={classes.select}>
            { cmsConfig?.customersGroupManagement && (
              <ScrollSelectPicker
                disabled={loadingCustomers}
                selectList={customerGroups}
                selected={activeCustomerGroups}
                action={switchCustomerGroups}
                displayName={intl.messages["customers.filters.customerGroup.title"]}
                itemNbToDisplay="15"
                shouldIncludeSearch={true}
                searchWording={intl.messages["customers.filters.customerGroup.search"]}
              />
            )}
          </div>
          
          {
            isFilteringByBlacklisted && (
              <div className={classes.selectReason}>
                <SelectPicker
                  disabled={loadingCustomers}
                  className={classes.reasonPicker}
                  selectList={blackListStatusReasonNames}
                  selected={fullSelectedStatusReasonNames}
                  action={switchStatusReasons}
                  displayName={intl.messages["customers.filters.statusReason.title"]}
                />
              </div>
            )
          }
          {
            (isFilteringByInvalid && invalidStatusReasonNames.length) ? (
              <div className={classes.selectReason}>
                <SelectPicker
                  disabled={loadingCustomers}
                  className={classes.reasonPicker}
                  selectList={filteredInvalidStatusReasonNames}
                  selected={filteredSelectedStatusReasonNames}
                  action={switchStatusReasons}
                  displayName={intl.messages["customers.filters.statusReason.title"]}
                />
              </div>
            ) : null
          }
    
          <KiTextField 
            placeholder={intl.messages["products.filters.search.placholder"]}
            type="whiteBg"
            autoComplete="off"
            name="customer_search"
            handleChange={changeSearch} > 
          </KiTextField>
          
        </div>
        {
          shouldShowButtons ? (
            <div className={classes.ctasGroup}>

              {shouldShowBrevoMailChimpSynchronizeButton ? 
                <Tooltip 
                  title={
                    emailingPlatformsInAccount.length > 1 ? 
                      intl.formatMessage({ id: "customers.tooltips.synchronize_button_plural" }, {platforms: emailingPlatformsInAccount.join(" / ")}) :
                      intl.formatMessage({ id: "customers.tooltips.synchronize_button_singular" }, {platform: emailingPlatformsInAccount[0]})
                  } 
                  placement="top"
                >
                  <span>
                    <FormButtonTO
                      type={"secondary"}
                      size={"xs"}
                      action={syncBrevoMailchimp}
                      label={intl.messages["integration.cmsCard.synchronize"]}
                    />
                  </span>
                </Tooltip> : null }
                
              {exportCustomersCSVLoading ? <CircularProgress size={26} className={classes.loading} /> : null}
              <FormButtonTO
                type={"secondary"}
                size={"xs"}
                action={exportContacts}
                label={intl.messages["button.export"]}
                icon={<i className='fal fa-arrow-alt-to-top' />}
                isResponsive={"true"}
              />
              <KiDropdown
                placement={"bottom-start"}
                variant="secondary"
                placeholder={intl.messages["button.action"]}
                options={[
                  { value: "blacklist", label: intl.messages["button.import_blacklist"], },
                  { value: "whitelist", label: intl.messages["button.import_whitelist"], },
                  shouldShowLanguageButton && { value: "langSetting", label: intl.messages["button.lang_settings"], },
                  cmsConfig?.customersGroupManagement && { value: "groupSetting", label: intl.messages["button.group_settings"], },
                ]}
                onChange={switchAction}
              />
            </div>
          ) : null
        }
      </div>
    );
  }
})));

const StatsCard = withStyles(styles)(
  class StatsCard extends Component {

    render() {
      const {
        classes,
        loading
      } = this.props;

      return (
        <Tooltip title={this.props.tooltip} arrow>
          <Paper>
            <div className={`${loading ? classes.loading : classes.paperCard} ${classes.paperCard}`}>
              {
                loading
                  ? <CircularProgress color={"secondary"} size="30px" />
                  : 
                  <div className={classes.dataPaperCard}>
                    <i className={`fal fa-${this.props.icon} ${classes.iconPaperCard}`}></i>
                    <div className={classes.numberPaperCard}>
                      {getFormattedNumber(this.props.number.toString(), undefined, "fr")}
                      <div className={classes.textPaperCard}>{this.props.text}</div>
                    </div>
                  </div>
              }
             
            </div>
          </Paper>
        </Tooltip>
      );
    }
  }
);

const BigStatsCard = withStyles(styles)(injectIntl(class BigStatsCard extends Component {

  render() {
    const {
      classes,
      intl,
      loading
    } = this.props;

    return (
      <Paper>
        <div className={`${loading ? classes.loading : classes.bigPaperCard} ${classes.bigPaperCard}`}>
          {
            loading
              ? <CircularProgress color={"secondary"} size="30px" />
              : 
              <Tooltip title={intl.messages["customers.base.tooltip.subscribers"]} arrow>
                <div className={classes.dataPaperCard}>
                  <i className={`fal fa-${this.props.icon1} ${classes.iconPaperCard}`}></i>
                  <div className={classes.numberPaperCard}>
                    {getFormattedNumber(this.props.number1.toString(), undefined, "fr")}
                    <div className={classes.textPaperCard}>{this.props.text1}</div>
                  </div>
                </div>
              </Tooltip>
          }
          {
            loading
              ? 
              <CircularProgress color={"secondary"} size="30px" />
              : 
              <Tooltip title={intl.messages["customers.base.tooltip.invalid.contacts"]} arrow>
                <div className={classes.dataPaperCard}>
                  <i className={`fal fa-${this.props.icon2} ${classes.iconPaperCard}`}></i>
                  <div className={classes.numberPaperCard}>
                    {getFormattedNumber(this.props.number2.toString(), undefined, "fr")}
                    <div className={classes.textPaperCard}>{this.props.text2}</div>
                  </div>
                </div>
              </Tooltip>
          }
          {
            loading
              ? <CircularProgress color={"secondary"} size="30px" />
              : 
              <Tooltip title={intl.messages["customers.base.tooltip.blacklisted"]} arrow>
                <div className={classes.dataPaperCard}>
                  <i className={`fal fa-${this.props.icon3} ${classes.iconPaperCard}`}></i>
                  <div className={classes.numberPaperCard}>
                    {getFormattedNumber(this.props.number3.toString(), undefined, "fr")}
                    <div className={classes.textPaperCard}>{this.props.text3}</div>
                  </div>
                </div>
              </Tooltip>
          }
        </div>
      </Paper>
    );
  }
}
));
